const ACTIONS = {
  UPDATE_FRAME_INDEX: 'SNAP_SCROLL:UPDATE_FRAME_INDEX',
  DISABLE: 'SNAP_SCROLL:DISABLE',
  COUNT: 'SNAP_SCROLL:COUNT',
  RESET: 'SNAP_SCROLL:RESET',
  IS_LAST_FRAME: 'SNAP_SCROLL:IS_LAST_FRAME',
};

export default {
  ACTIONS,
};
